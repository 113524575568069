import { createSlice } from '@reduxjs/toolkit';

const systemConfigSlice = createSlice({
  name: 'systemConfig',
  initialState: { config: null },
  reducers: {
    getSystemConfig: (state, action) => {
      state.config = action.payload;
    },
  },
});

export const { getSystemConfig } = systemConfigSlice.actions;
export default systemConfigSlice.reducer;
