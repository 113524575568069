import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(
  ({ disabledLink = false, type = 'single', sx, ...other }, ref) =>
    // const logo = (
    //   <Box
    //     component="img"
    //     src={`/logo/logo_${type}.png`}
    //     sx={{ height: 40, objectFit: 'contain', cursor: 'pointer', ...sx }}
    //   />
    // );

    // if (disabledLink) {
    //   return logo;
    // }

    // return (
    //   <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
    //     {logo}
    //   </Link>
    // );

    null
);

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  type: PropTypes.string,
};

export default Logo;
